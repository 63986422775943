import '../styles/globals.css';
import '../styles/expander.css';
import '../styles/modal.css';

// import 'nextra-theme-docs/style.css';
import 'antd/dist/antd.css';

import '@phpcreation/frontend-components-react/build.css';

import type { AppProps } from 'next/app';
import React from 'react';
import { AppContextProvider } from '@phpcreation/frontend-components-react';

import { API_ENDPOINTS } from '@/utils/constants/constants';

import en from '@/utils/locales/en/common.json';
import fr from '@/utils/locales/fr/common.json';
import bi from '@/utils/locales/bi/common.json';

const messages = {
  en,
  fr,
  bi,
};

// For testing components
const endpoints = {
  crud: process.env.NEXT_PUBLIC_CONFIG_CRUD!,
  auth: process.env.NEXT_PUBLIC_CONFIG_AUTH!,
  status: process.env.NEXT_PUBLIC_CONFIG_STATUS!,
  documentation: process.env.NEXT_PUBLIC_CONFIG_DOCUMENTATION!,
};

function MyApp({ Component, pageProps }: AppProps) {
  return (
    <AppContextProvider endpoints={endpoints} extraMessages={messages}>
      <Component {...pageProps} />
    </AppContextProvider>
  );
}
// export default MyApp;

export default MyApp;
